import './App.css'
import { WebApp } from '@grammyjs/web-app'
import { useEffect, useState } from 'react'
import { HexColorPicker } from 'react-colorful'

const server = 'https://themebotbot.maonymous.ru/confirm-select/'
// const server = 'https://32d7-176-110-129-73.ngrok-free.app/confirm-select/'

const locales = {
  ru: {
    backgroundColor: 'Цвет фона',
    mainText: 'Основной текст',
    secondaryText: 'Второстепенный текст',
    select: 'Выбрать',
  },
  en: {
    backgroundColor: 'Background color',
    mainText: 'Main text',
    secondaryText: 'Secondary text',
    select: 'Select',
  },
  uk: {
    backgroundColor: 'Колір фону',
    mainText: 'Основний текст',
    secondaryText: 'Другорядний текст',
    select: 'Вибрати',
  },
}

const query = new URLSearchParams(window.location.search)
const lang = query.get('lang') as 'ru' | 'en' | 'uk'
const locale = locales[lang]
const variant = query.get('variant')
const isPair = query.get('pair')
let variantText: string
switch (variant) {
  case '1':
    variantText = locale.backgroundColor
    break
  case '2':
    variantText = locale.mainText
    break
  case '3':
    variantText = locale.secondaryText
    break
}

function App() {
  const [color, setColor] = useState('#aabbcc')

  const onClickMainButton = async () => {
    WebApp.MainButton.showProgress()
    WebApp.MainButton.disable()

    let url = server
    if (isPair) url = 'https://themebotbot.maonymous.ru/confirm-select-pair/'
    await fetch(
      url +
        WebApp.initDataUnsafe.user?.id +
        '/' +
        color.substring(1) +
        '/' +
        lang +
        '/',
      {
        method: 'GET',
      }
    )

    await WebApp.close()
  }

  useEffect(() => {
    WebApp.MainButton.setText(locale.select)
    WebApp.MainButton.enable()
    WebApp.MainButton.show()
    WebApp.expand()
  }, [])

  useEffect(() => {
    WebApp.MainButton.onClick(onClickMainButton)
    return () => {
      WebApp.MainButton.offClick(onClickMainButton)
    }
  }, [onClickMainButton])

  return (
    <div className="App">
      <HexColorPicker color={color} onChange={setColor} />
      <div className="under-selector">
        <h1 className="stage-text">
          {color} {variantText}
        </h1>
      </div>
    </div>
  )
}

export default App
